












































import { Component, Vue } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';

@Component
export default class Store extends Vue {
    get dialogVisible() {
        return projectModule.dialogs.cleanFilters;
    }

    set dialogVisible(value: boolean) {
        this.close();
    }

    close() {
        projectModule.closeOrOpenDialog('cleanFilters');
        projectModule.setSearchFilters({});
    }

    next() {
        projectModule.activeOrDisabledChecked(false);
        projectModule.clearSelectedProjects();
        projectModule.closeOrOpenDialog('cleanFilters');
        projectModule.setFilter(projectModule.searchFilters);
        this.$router.push({
            query: {
                ...this.$route.query,
                ...(projectModule.searchFilters as any)
            }
        });
    }
}
